<template>
  <b-modal
    id="delete_entity"
    title="Delete Entity"
    size="lg"
    centered
  >
    Deleting {{ name }} will delete it and all children (and their respective attributes and aliases).
    Do you want to continue?

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" @click="submitFunction()">
        Delete
      </b-button>
      <b-button variant="outline-dark" @click="cancel()">
        Cancel
      </b-button>

    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    selected_entity2(newVal) {
      this.fillName(newVal)
    },
  },
  mounted() {
    if (this.selected_entity2?.context) this.fillName(this.selected_entity2)
  },
  methods: {
    fillName(val) {
      this.name = val?.context.details.name
    },
    submitFunction(e) {
      const params = {
        model: this.$store.state.model.id,
      }
      const parentId = this.selected_entity2?.context.parent_rel.parent
      // this.$http.delete(`/api/v2/domain_model/composition/${this.selected_entity2.context.details.id}`, { id: this.$store.state.model.id }, params).then(() => {
      this.$store.dispatch('domainModel/deleteComponent', this.selected_entity2?.context.details.id)
        .then(() => {
          this.$emit('deleted', parentId)
          this.$bvModal.hide('delete_entity')
        }).catch(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Base abstract entities cannot be deleted',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
